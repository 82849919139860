<template>
  <v-responsive class="error-occured fill-height">
    <v-card class="d-flex flex-column justify-center fill-height w-100">
      <v-card-title class="404-header fill-height">
        <label>
          <img src="../assets/404.svg" alt="" />
          <h3 class="mt-4">Error 404 - Page not found</h3>
        </label>
      </v-card-title>
    </v-card>
  </v-responsive>
</template>

<script>
export default {
  name: "404Error",
};
</script>